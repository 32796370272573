/**
 * A venue is either a real location such as Tate Modern
 * or a virtual location for online users.
 * It holds a list of canvases that can be picked
 * and a list of users that are allowed access.
 */
export type Venue = {
  slug: string;
  name: string;
  venueType: VenueType;
  canvases: Canvas[];
  restricted: boolean;
  users: User[];
  venueMode: VenueMode;
  slotsCount: number; // Number of drawing stations in the venue
};

export enum VenueType {
  Online = 'ONLINE',
  Venue = 'VENUE',
}

export enum VenueMode {
  Default = 'DEFAULT',
  Collaborative = 'COLLABORATIVE',
  Maintenance = 'MAINTENANCE',
  Off = 'OFF',
}

/**
 * A canvas is a starting point for a user to draw.
 * It also defines a drawing mode that can change
 * the way the user draws.
 */
export type Canvas = {
  id: number;
  name: string;
  width: number;
  height: number;
  order: number;
  backgroundColor: string;
  backgroundImageUrl: string | null;
  palette: Palette;
  drawingMode: DrawingMode;
  drawingOptions: DrawingModeOptions;
};

export type DrawingModeOptions =
  | null
  | {}
  | DrawingOptionPixel
  | DrawingOptionsMirror
  | DrawingOptionsObserve
  | DrawingOptionsInspiration
  | DrawingOptionsWordPrompt;

/**
 * A color palette is a list of colors that can be used
 * in a canvas. Using labeled colors for accessibility purposes
 */
export type Palette = {
  id: number;
  name: string;
  colors: LabeledColor[];
};

export type LabeledColor = {
  label: string;
  color: string;
};

/**
 * The list of drawing modes that can be used
 * in a canvas.
 * PIXEL: Force draw in a pixel grid
 * MIRROR: Mirrors each stroke vertically
 * CONTINUOUS: Game - Draw without lifting the mouse/pen
 * OBSERVE: Game - Observe an artwork for x seconds and then draw
 * INSPIRATION: An artwork is shown on the side as an inspiration
 * INVISIBLE_INK: Game - Draw without showing the ink
 * WORD_PROMPT: Game - Prompt the user to draw using 2 words
 *              (adjective + noun - e.g. "Screaming House")
 */
export enum DrawingMode {
  Normal = 'NORMAL',
  Pixel = 'PIXEL',
  Mirror = 'MIRROR',
  Continuous = 'CONTINUOUS',
  Observe = 'OBSERVE',
  Inspiration = 'INSPIRATION',
  InvisibleInk = 'INVISIBLE_INK',
  WordPrompt = 'WORD_PROMPT',
}

export type DrawingOptionPixel = {
  pixelSize: number;
};

export type DrawingOptionsMirror = {
  isVerticalAxis: boolean;
};

export type DrawingOptionsObserve = {
  artworkImage: Image;
  observeDurationInSeconds: number; // seconds
};

export type DrawingOptionsInspiration = {
  artworkImage: Image;
};

export type DrawingOptionsWordPrompt = {
  wordPrompt?: { first: string; second: string };
  wordsA: string[];
  wordsB: string[];
};

/**
 * Drawing data
 * Exact options TBC
 */
export type Drawing = {
  id: string;
  localId?: number;
  timestamp: number;
  name?: string;
  imageUrl: string; // image URI or image URL (after being uploaded to the server)
  strokes: Stroke[];
  canvas: Canvas;
  userId?: number;
  venueSlug: string;
  consentTimestamp?: number;
  submittedTimestamp?: number;
  selectedTimestamp?: number;
  submitter?: Submitter;
};

export type Point = {
  x: number;
  y: number;
  p?: number; //pressure
};

export type Stroke = {
  t?: number; // timestamp
  points: Point[];
  color: string;
  thickness: number;
  isEraser: boolean;
};

export enum StrokeThickness {
  Small = 8,
  Medium = 14,
  Large = 40,
}

export type SpriteSheet = {
  url: string;
  canvas: HTMLCanvasElement;
  stampDiameter: number;
  columns: number;
  rows: number;
};

export const eraserDensities = [
  { strokeThickness: 8, eraserDensity: 10 },
  { strokeThickness: 14, eraserDensity: 15 },
  { strokeThickness: 40, eraserDensity: 75 },
];

/**
 * Mainly used to identify a drawing station
 * in a physical venue
 */
export type User = {
  id: number;
  name: string;
  password?: string;
  token?: string;
  timestamp: number;
  email: string;
  role: UserRole;
  venue?: Venue;
  slot?: number;
};

export type UserCreate = Omit<User, 'id' | 'timestamp'>;

export enum UserRole {
  Anonymous = 'ANONYMOUS',
  Admin = 'ADMIN',
  Tablet = 'TABLET',
  Projection = 'PROJECTION',
  Projector = 'PROJECTOR',
  TateKidsGallery = 'TATEKIDSGALLERY',
  Remote = 'REMOTE',
}

export type Device = {
  id: number;
  name: string;
  timestamp: number;
  role: string;
  venueSlug: string;
  ip: string;
  clientId: string;
};

export type Image = {
  url: string;
  caption?: string;
  alt?: string;
  width: number;
  height: number;
};

export type CountryGroups = {
  frequent: string[];
  all: string[];
};

export type TeemillOption = {
  item_code: string;
  name: string;
  colours: {
    [key: string]: string;
  };
};

export type Submitter = {
  name: string;
  age: number;
  country: string;
};

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

/**
 * Used for carousels in the Drawing App
 */
export type Slide = {
  backgroundColor: string;
  backgroundImage: string;
  id: number | string;
  type?: string;
  width: number;
  height: number;
  mirrorAxis?: DrawingOptionsMirror;
};

/**
 * For the projection app
 */
export type DrawingEvent = {
  type: 'posted' | 'curated' | 'animated';
  timestamp: number;
  drawing: Drawing;
};

export type GridSectionType = {
  id: number;
  tabletSlot: number; // Target the tablet with this slot
  type: 'grid' | 'single';
  drawingEvents: DrawingEvent[];
};

export type ProjectionAppSettings = {
  projectionWidth: number;
  projectionHeight: number;
  projectionXScale: number;
  backgroundColor: string;
  gap: number;
  sectionWidthGrid: number;
  sectionWidthSingle: number;
  gridRefreshIntervalMs: number;
  drawingMaxAgeMs: number;
  frameFormats: Record<string, number>;
  frameSizes: Record<string, number>;
  drawingFrameBorders: Record<string, { border: number; borderImage: number }>;
  drawingFrameConfig: {
    fadeIn: { duration: number; delay: number; staggerDelay: number };
    fadeOut: { duration: number; delay: number; staggerDelay: number };
  };
  postedDrawingConfig: {
    pauseDuration: number;
    fly: { y: number; duration: number; delay: number };
    fadeScale: { duration: number; baseScale: number };
  };
  gridSections: GridSectionType[];
};

export type ProjectionDrawings = {
  portrait: PartialDrawing[];
  landscape: PartialDrawing[];
  square: PartialDrawing[];
};

export type PartialDrawing = {
  id: string;
  name: string;
  timestamp: number;
  imageUrl: string;
  canvasId: number;
  canvasWidth: number;
  canvasHeight: number;
  userId: number;
  venueSlug: string;
};

export type Notification = {
  duration?: number;
  id?: number;
  isError?: boolean;
  isInvisible?: boolean;
  isSuccess?: boolean;
  message: string;
};

export type CollabSession = {
  id: number;
  venueSlug: string;
  imageUrls: string[];
  createdAt: number;
  updatedAt: number;
};
